<template>
  <base-section id="k-d-a-to-team" space="80" class="background-journey" top-inner-shadow>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          :lg="`${g_bBaseBr ? 6 : 5}`"
          :md="`${g_bBaseBr ? 6 : 5}`"
          style="flex-direction: column; display: flex; align-items: center"
        >
          <div class="img-container center">
            <img
              :src="require('@/assets/photos/story-02.png')"
              :class="g_bLowestBr ? 'top' : g_bLowerBr ? 'left' : g_bBaseBr ? 'left-base' : 'left'"
            />
          </div>
        </v-col>
        <v-col v-if="g_bHighestBr" lg="1" />
        <v-col v-else-if="g_bBaseBr" lg="1" />
        <v-col v-else-if="g_bHigherBr" lg="1" />
        <v-col
          cols="12"
          :lg="`${g_bBaseBr ? 5 : 6}`"
          :md="`${g_bBaseBr ? 5 : 6}`"
          :style="stylesText"
        >
          <div v-for="(item, id) in m_arrContents" :key="id">
            <component :is="item.strComponent" :class="`kda-ts-${g_bLowerBr ? 20 : 46}pt wt-black`">
              {{ item.strText }}
            </component>
            <v-btn
              color="main-r-400"
              class="text-capitalize white--text mt-4 mt-md-8"
              :x-large="$vuetify.breakpoint.mdAndUp"
              @click="navToProfileTeam"
            >
              {{ m_strTeam }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import TextClass from '@/mixins/text-class';
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';

export default {
  name: 'KDAStoryToTeam',

  mixins: [TextClass, BusinessDetails, BaseBreakpoint],

  computed: {
    m_strTeam() {
      return this.$vuetify.lang.t('$vuetify.kda.story.to-team.meet-team');
    },
    m_arrContents() {
      return [
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.story.to-team.desc'),
          strComponent: 'h1'
        }
      ];
    },
    stylesText() {
      return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: `${this.$vuetify.breakpoint.smAndDown ? '0px 20px 20px' : '0px'}`
      };
    }
  },

  methods: {
    navToProfileTeam() {
      window.gtag('event', 'click', {
        'event_label': 'Meet The Team'
      });
      this.$router.push('teams');
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.background-journey {
  background-color: #f8f8f8;
}

.img-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    object-fit: cover;
    &.left {
      height: 460px;
      width: 460px;
    }

    &.left-base {
      height: fit-content;
      width: 100%;
      object-fit: cover;
    }

    &.top {
      left: 0;
      top: 0;
      width: 100%;
      position: relative;
      padding: 0px 10px;
    }
  }
}
</style>
